import { serializeLabel } from '../../redux/ducks/resource-edges';
import { nestParams } from '../params';
import { schema } from '@cube3/common/model/schema';
import { ResourceType } from '@cube3/common/model/resource-types';

/**
 *  INTERCEPT REQUESTS
 */

const fakeJSONApiEndpointsBlackList = [
  /\/folders\/[^/]*\/relationships\/folder\//,
  /\/assets\/[^/]*\/relationships\/folder\//
];

const isProbablyJSONApi = (req) => {
  return (
    req.url.includes('/relationships/') &&
    /** NOTE: legacy copy paste used JSONApi inspired urls */
    !fakeJSONApiEndpointsBlackList.filter((b) => b.test(req.url)).length
  );
};

// app stores resource attributes on object root
// need to transform back into sub object under 'attributes' key
// when Creating or Updating resources
const formatJSONApiResourcePatch = (req) => {
  const { uuid, id, type, relationships, ...rest } = req.data;

  if (isProbablyJSONApi(req)) {
    return req.data;
  }

  let redundant = {};
  if (type === 'asset') {
    redundant = rest;
  }

  return {
    data: {
      id: uuid || id,
      type,
      attributes: rest,
      relationships,
      ...redundant
    },
    meta: req.meta,
    included: req.included,
    ...redundant // TODO: remove once assets api is fixed
  };
};

// insert json api headers and apply formatter
// depending on the request method
function requestInterceptor(req) {
  if (this.JSONApi || isProbablyJSONApi(req)) {
    // TODO: remove when all endpoints are jsonAPI
    req.headers = {
      ...req.headers,
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json'
    };

    if (req.method.toUpperCase() !== 'GET') {
      // make updating relationships work
      if (Array.isArray(req.data?.data)) {
        req.data.data.forEach((e) => {
          if (e.type) {
            e.type = fixType(e.type, true);
          }
        });
      } else if (typeof req.data?.data === 'object' && req.data.data.type) {
        req.data.data.type = fixType(req.data.data.type, true);
      }
    }

    switch (req.method.toUpperCase()) {
      case 'PUT':
        console.warn('This method should not be used with JSON:API spec');
        break;
      case 'PATCH':
        req.data = formatJSONApiResourcePatch(req);
        break;
      case 'POST':
        req.data = formatJSONApiResourcePatch(req);
        break;
      default:
        break;
    }
  }
  return req;
}

// workaround for when the api returns plural form of a resource type
// TODO: remove this once API has stabilized and returns either singular or plural for all types
const fixType = (
  // resource type
  type: string,
  // Whether to return the schema type or the type the server uses
  // yes... these should be the same
  // no... they aren't
  getServerType = false
) => {
  const guess = schema.guessResource(type);
  let normalizedType = getServerType ? guess?.serverType : guess?.type;

  // currently the server return search-result as type for both legacy and new results
  // this interceptor only runs for the new jsonapi endpoint, so the below works but it's brittle
  if (type === 'search-result') {
    normalizedType = getServerType ? 'meili-search-result' : 'search-result';
  }

  if (normalizedType && normalizedType !== type) {
    console.warn(`Had to normalize type ${type} to ${normalizedType}`);
  }

  return normalizedType || type;
};

/**
 *  INTERCEPT RESPONSES
 */

const parseJSONApiResource = (resource) => {
  const parsed = {
    type: fixType(resource.type || resource.data?.type),
    id: resource.id || resource.data?.id,
    __stub__: resource.__stub__,
    // '__raw__': res.resource,
    attributes:
      resource.attributes || (resource.data && resource.data.attributes) || {},
    meta: resource.meta || {},
    relationships: Object.keys(
      resource.relationships ||
        (resource.data && resource.data.relationships) ||
        {}
    ).reduce((acc, key, idx, arr) => {
      const source =
        resource.relationships || resource.data?.relationships || {};
      const { data, meta } = source[key];

      let parsedData = { [key]: null };

      if (data) {
        parsedData = {
          [key]: Array.isArray(data)
            ? data.map((e) => {
                return {
                  id: e.id,
                  type: fixType(e.type)
                };
              })
            : {
                id: data.id,
                type: fixType(data.type)
              }
        };
      }
      // keep `meta` value
      // use case: `GET: tags/id/`, instead of returning all the `nodes` in its relationship,
      // we could use `relationships.nodes.meta.totalSize` to know how many files the tag has been used on
      // if (meta) {
      //   return {
      //     ...acc,
      //     [key]: {
      //       data: parsedData,
      //       meta
      //     }
      //   };
      // }

      return {
        ...acc,
        ...parsedData
      };
    }, {}),
    links: Object.keys(
      resource.relationships ||
        (resource.data && resource.data.relationships) ||
        {}
    ).reduce((acc, key, idx, arr) => {
      const source =
        resource.relationships ||
        (resource.data && resource.data.relationships) ||
        {};
      return {
        ...acc,
        [key]: source[key].links
      };
    }, {})
  };
  if (!schema.validateResource(parsed)) {
    // console.warn('%cBad resource', 'color: #552', parsed);
  }
  return parsed;
};

const parseRelationShips = (n, idx): JSONApiEdge[] =>
  Object.keys(n.relationships || {})
    .filter((k) => n.relationships[k]) // make sure relationship is not null
    .map((k) =>
      [].concat(n.relationships[k]).map((r) => {
        const parsed = {
          name: k.split('?')[0],
          label: k, // TODO: find way to include relevant query params in label
          pageNumber: r.page,
          from: { type: n.type, id: n.id },
          to: {
            type: r.data ? r.data.type : r.type,
            id: r.data ? r.data.id : r.id
          }
        };
        if (!schema.validateRelationship(parsed)) {
          // console.warn('%cBad relationship', 'color: #552', parsed);
          return parsed;
        } else {
          const rev = schema.getReverse(parsed.from.type, parsed.name);
          return [parsed, { from: parsed.to, to: parsed.from, name: rev }];
        }
      })
    )
    .flat(2);

const parseMeta = (meta, listSize) => {
  if (meta || listSize === 0) {
    let totalSize;
    switch (true) {
      case meta?.totalSize !== undefined:
        totalSize = meta.totalSize;
        break;
      case meta?.NbHits !== undefined:
        totalSize = meta.NbHits;
        break;
      case listSize !== undefined:
        totalSize = listSize;
        break;
      default:
        break;
    }

    return {
      ...(meta || {}),
      totalSize
    };
  }
};

const parseRequest = ({ config, data }) => {
  const { __parent_resource = {} } = data.meta || {};

  const url = config.url;
  const params = config.params || {};
  const endpoint = url.split(config.baseURL).slice(-1)[0];
  const fragments = endpoint.split('/').filter((f) => !!f);
  const mappedParams = nestParams(params);

  return {
    resourceType: fixType(
      schema.getSingular(__parent_resource.by || fragments[0])
    ),
    resourceId: __parent_resource.id || fragments[1],
    nested:
      fragments.length > 2 || (__parent_resource.by && __parent_resource.id),
    relationship:
      fragments.length < 2 && __parent_resource.by
        ? fragments[0]
        : fragments[2] === 'relationships'
        ? fragments[3]
        : fragments[2],
    isRelationshipEndpoint: fragments[2] === 'relationships',
    params: {
      ...mappedParams
    },
    rawParams: params,
    config,
    fragments
  };
};

export const getContentType = (res) => {
  if (!res.headers) {
    return {};
  }
  const contentType =
    res.headers['Content-Type'] || res.headers['content-type'];
  if (!contentType) {
    return {};
  }

  return {
    type: contentType.split(';')[0],
    encoding: contentType.split(';')[1]
  };
};

export const isJSONApiResponse = (res) => {
  return getContentType(res).type === 'application/vnd.api+json';
};

function responsetInterceptor(res): JSONApiInterceptorResponse | unknown {
  switch (getContentType(res).type) {
    case 'application/vnd.api+json': {
      const req = parseRequest(res);
      if (req.config.method.toUpperCase() === 'DELETE') {
        return res;
      }
      const nodes = [];
      const included = [];
      const edges = [];
      const { data, meta } = res.data;
      let primary;

      if (Array.isArray(data)) {
        if (req.isRelationshipEndpoint) {
          edges.push(
            ...data.map((e) => {
              return {
                name: req.relationship,
                label: serializeLabel(`relationships/${req.relationship}`, {}),
                pageNumber: undefined,
                from: {
                  type: fixType(req.resourceType),
                  id: req.resourceId
                },
                to: {
                  type: fixType(e.type),
                  id: e.id
                }
              };
            })
          );
          nodes.push(
            ...data.map((e) => ({ ...e, attributes: {}, __stub__: true }))
          );
        } else {
          nodes.push(...data);
        }
        primary = data.length;
      } else {
        const resource = data;

        if (['PUT', 'PATCH'].indexOf(req.config.method.toUpperCase()) !== -1) {
          // TODO: remove this check when API becomes stable
          if (!resource.attributes) {
            console.warn('resource has no attributes. Injecting empty object');
            resource.attributes = {};
          }
          // TODO: make sure this doesn't mess up patching relationships
          if (!resource.attributes.updated_at) {
            console.warn('manually setting updated_at');
            resource.attributes.updated_at = new Date().toISOString();
          }
        }

        if (!req.nested && resource.id !== req.resourceId) {
          console.warn(
            "Id's of requested and responded resource don't match",
            req,
            res.data
          );
        }
        primary = 1;
        nodes.push(resource);
      }

      if (req.relationship) {
        // // console.info(req);
        // // TODO: should get this relationship from api
        // // TODO: should use schema to check if relationships is allowed
        // const rev = schema.getReverse(req.resourceType, req.relationship);
        // nodes.forEach( n => {
        //   n.relationships = n.relationships || {};
        //   n.relationships[ rev ] = n.relationships[rev] ? n.relationships[rev] : {
        //     data: {type: req.resourceType, id: req.resourceId},
        //     links: {related: `${req.resourceType}/${req.resourceId}`},
        //   }
        // })
      }

      if (res.data.included) {
        included.push(...res.data.included);
      }

      const parsedNodes = nodes.map(parseJSONApiResource);
      const parsedIncluded = included.map(parseJSONApiResource);
      const inferedRelations = [];
      const inferedEdges = [];

      if (Array.isArray(data) && req.nested) {
        inferedRelations.push({
          type: req.resourceType,
          id: req.resourceId,
          relationships: {
            [serializeLabel(req.relationship, req.params)]: nodes.map((n) => {
              return {
                page: req.params.page?.number,
                data: { type: fixType(n.type), id: n.id },
                links: { related: `${n.type}/${n.id}` }
              };
            })
          }
        });
      } else if (req.config.method.toUpperCase() === 'POST' && req.nested) {
        inferedRelations.push({
          type: req.resourceType,
          id: req.resourceId,
          relationships: {
            [serializeLabel(req.relationship, req.params)]: nodes.map((n) => {
              return {
                data: { type: fixType(n.type), id: n.id },
                links: { related: `${n.type}/${n.id}` }
              };
            })
          }
        });
      } else if (Array.isArray(data) && !req.nested) {
        inferedEdges.push(
          ...nodes.map((n) => {
            return {
              name: req.fragments[0],
              label: serializeLabel(req.fragments[0], req.params),
              pageNumber: req.params.page?.number,
              from: {},
              to: {
                type: fixType(n.type),
                id: n.id
              }
            };
          })
        );
      }

      edges.push(
        ...[...inferedRelations, ...parsedNodes, ...parsedIncluded].flatMap(
          parseRelationShips
        )
      );

      edges.push(...inferedEdges);

      res.data = {
        nodes: parsedNodes,
        // .map( n => {
        //   const {relationships, ...node} = n;
        //   return node;
        // }),
        included,
        edges,
        primary:
          primary !== undefined ? parsedNodes.slice(0, primary) : undefined,
        meta: parseMeta(
          res.data.meta,
          Array.isArray(data) ? data.length : undefined
        ),
        links: res.data.links
      };
      return res;
    }
    default:
      return res;
  }
}

export const JSONApiInterceptors = {
  requestInterceptor: requestInterceptor,
  responseInterceptor: responsetInterceptor
};

interface JSONApiResource {
  id: string;
  type: ResourceType;
  attributes: {
    [key: string]: any;
  };
}

interface JSONApiEdge {
  name: string;
  label?: string;
  pageNumber?: any;
  from: {
    type: ResourceType;
    id: string;
  };
  to: {
    type: ResourceType;
    id: string;
  };
}
export interface JSONApiInterceptorResponse {
  data: {
    nodes: Array<JSONApiResource>;
    included: Array<JSONApiResource>;
    primary: Array<JSONApiResource>;
    edges: Array<JSONApiEdge>;
    meta: {
      [key: string]: unknown;
      totalSize: number;
    };
  };
}

import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

// ui
import { ModalMenuUI } from '../../../../Modal/ModalMenuUI';
import Button from '../../../../Buttons/Button';
import { Typography } from '../../../../typography/Typography';
import { Divider } from '../../../../Surface/Divider';

// forms and textfields
import LinkSettingsContent from '../../LinkSettingsContent';
import { useGlobalSubmit } from '@cube3/cubicle/src/helpers/hooks/useGlobalSubmit';

/// <Summary>
/// UI component for share link overview
/// </Summary>

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerButtonMargin: {
      marginLeft: '8px'
    },
    content: {
      minHeight: '70vh'
    },
    container: {
      padding: `0px 0px ${theme.customSpacing.padding[24]} 0px`
    },
    subTitle: {
      marginBottom: theme.customSpacing.margin[16]
    },
    optionsText: {
      paddingBottom: 12
    },
    cancelButton: {
      color: theme.customPalette.primary.textWhiteLowEmphasis,
      marginRight: '10px',
      border: `${theme.customPalette.primary.lineWhiteLowEmphasis} 1px solid`,
      '&:hover': {
        backgroundColor: 'initial'
      }
    },
    submitButton: {
      backgroundColor: theme.customPalette.secondary.light,
      color: theme.customPalette.primary.textWhiteLowEmphasis,
      '&:hover': {
        backgroundColor: theme.customPalette.secondary.light
      }
    }
  })
);

interface ShareLinkOverviewProps {
  loading: boolean;

  // events
  onCloseEvent: () => void;
  onClickCreateShare: () => void;

  // render props
  emailBox: React.ReactNode;
  linkSettings: React.ReactNode;

  submitDisabled: boolean;
}

const ShareNewLinkUI: React.FC<ShareLinkOverviewProps> = (props) => {
  const {
    loading,
    onCloseEvent,
    onClickCreateShare,
    submitDisabled,
    emailBox,
    linkSettings
  } = props;

  const handleCloseEvent = React.useCallback(
    () => onCloseEvent(),
    [onCloseEvent]
  );
  const handleClickCreateShare = React.useCallback(
    () => onClickCreateShare(),
    [onClickCreateShare]
  );

  const classes = useStyles();

  const disabledCheck = React.useCallback(() => {
    return (
      submitDisabled ||
      loading ||
      (document.activeElement.tagName === 'INPUT' &&
        document.activeElement.ariaAutoComplete === 'list') // this is the downshift email suggestions dropdown
    );
  }, []);

  useGlobalSubmit(handleClickCreateShare, disabledCheck);
  return (
    <ModalMenuUI
      loading={loading}
      // TODO do viewheight here multiply
      title={'New Share Link'}
      multiplyContentSpace={10}
      onCloseEvent={handleCloseEvent}
      footerRightComponent={
        <>
          <Button
            text={'Cancel'}
            colorVariant="ghost2"
            onClick={handleCloseEvent}
          />
          <Button
            text={'Send link'}
            classes={{ root: classes.footerButtonMargin }}
            colorVariant={'filled1'}
            onClick={handleClickCreateShare}
            disabled={submitDisabled}
          />
        </>
      }
    >
      <div className={classes.container}>
        <Typography
          component="span"
          typographyStyle={'heading3'}
          color="contrast2"
          className={classes.subTitle}
        >
          Send links to
        </Typography>

        {emailBox}
      </div>

      <Divider />

      <LinkSettingsContent containerStyles={classes.container}>
        <Typography
          component="span"
          typographyStyle={'heading3'}
          color="contrast2"
          className={classes.subTitle}
        >
          Options
        </Typography>
        {linkSettings}
      </LinkSettingsContent>
    </ModalMenuUI>
  );
};

export default ShareNewLinkUI;
